<template>
  <div class="team">
    <v-container class="indigo lighten-5 my-5">
      <v-card class="white pa-3">
        <h1 class="text-center mb-3 subtitle-4 black--text"> الصفوف والشعب </h1>
        <v-row>
          <v-col md="3" sm="3" cols="12" align-self="center">
            <v-btn tile color="primary" @click="addDialog.open = true"> اضافة صف وشعبة <v-icon right
                style="font-size: 15px"> fa-plus </v-icon>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col md="3" sm="3" cols="12">
            <v-text-field v-model="table.search" label="البحث" append-icon="mdi-magnify" single-line hide-details>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table :loading="table.loading" loading-text="جاري التحميل ... الرجاء الانتظار" :headers="headers"
              :items="driversData" :search="table.search" :items-per-page="10" item-key="class_school_id"
              class="elevation-1" :footer-props="{
                showFirstLastPage: true,
              }">
              <template slot="item._id" slot-scope="props"> {{ props.index + 1 }} </template>
              <template v-slot:item.qrcode="{ item }">
                <qrcode-vue :value="item._id" :size="size" level="H" />
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="editItem(item)">
                      fa-edit </v-icon>
                  </template>
                  <span>تعديل</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-if="item.account === 0" color="#FF5252" class="ml-2" v-bind="attrs" size="20" v-on="on"
                      @click="deleteItem(item)"> fa-trash </v-icon>
                  </template>
                  <span>حذف</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="#FDD835" v-bind="attrs" size="20" class="ml-2" v-on="on" @click="printPage(item)">
                      fa-print </v-icon>
                  </template>
                  <span>طباعة</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <!-- add Class dialog -->
    <v-dialog v-model="addDialog.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة صف وشعبة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDialog.isFormValidAdd">
              <v-row>
                <v-col cols="12">
                  <v-select v-model="class_data.selectedClass" :items="classes" item-text="classes_name"
                    :rules="addRules.classes_idRules" label="الصف" outlined></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="class_data.class_school_leader" label="الشعبة"
                    :rules="addRules.class_school_leaderRules" outlined></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="class_data.lecture_amount" label="مبلغ المحاضرة" @keypress="isNumber($event)"
                    outlined></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addDialog.open = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="addDialog.saveLoading" :disabled="!addDialog.isFormValidAdd"
            @click="AddClass"> اضافة </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add Class dialog -->
    <!-- edit Class dialog -->
    <v-dialog v-model="dialogEdit.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">تعديل الصف والشعبة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="dialogEdit.isFormValid">
              <v-row>
                <v-col cols="12">
                  <v-select v-model="editedItem.class_name" :items="classes" item-text="classes_name"
                    :rules="addRules.classes_idRules" label="الصف" outlined></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="editedItem.leader" label="الشعبة" :rules="addRules.class_school_leaderRules"
                    outlined></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="editedItem.lecture_amount" label="مبلغ المحاضرة" @keypress="isNumber($event)"
                    outlined></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEdit.open = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="dialogEdit.loading" :disabled="!dialogEdit.isFormValid"
            @click="editItemConform"> تعديل </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit Class dialog -->
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من حذف هذا العنصر ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"> الغاء </v-btn>
          <v-btn color="primary white--text" :loading="deleteItemLoading" @click="deleteItemConfirm"> حذف </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from '@/api/api'
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    QrcodeVue,
  },
  data() {
    return {
      addDialog: {
        open: false,
        isFormValidAdd: false,
        saveLoading: false,
      },

      value: 'https://example.com',

      size: 40,

      deleteItemLoading: false,

      dialogDelete: false,

      addRules: {
        class_school_leaderRules: [value => !!value || 'الشعبة مطلوبة'],
        classes_idRules: [value => !!value || 'الصف مطلوب'],
      },

      editedItem: {},

      class_data: {
        selectedClass: null,
        class_school_leader: null,
        lecture_amount: null,
      },

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      table: {
        loading: false,
        search: null,
      },

      dialogEdit: {
        open: false,
        isFormValid: false,
        loading: false,
      },

      headers: [
        {
          text: '#',
          align: 'start',
          value: '_id',
          width: 1
        },
        {
          text: 'الصف',
          value: 'class_name',
        },
        { text: 'الشعبة', value: 'leader' },
        { text: 'مبلغ المحاظرة', value: 'lecture_amount' },
        { text: 'الكود', value: 'qrcode' },
        // { text: 'عدد الطلاب', value: 'student_number' },
        { text: 'العمليات', value: 'actions', sortable: false },
      ],
      driversData: [],
      classes: [],
    }
  },

  mounted() {
    this.getClasses()
    this.getData()
  },

  methods: {
    async getData() {
      this.table.loading = true

      const response = await Api.getClassSchool()

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.table.loading = false
        this.driversData = response.data.results
      }
    },

    deleteItem(item) {
      this.deletedItem = { ...item }
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.deleteItemLoading = true


      const response = await Api.removeClassSchool(this.deletedItem._id)
      if (response.status === 401) {
        this.dialogDelete = false
        this.deleteItemLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.dialogDelete = false
        this.deleteItemLoading = false
        this.getData()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    editItem(item) {
      this.editedItem = { ...item }
      this.dialogEdit.open = true
    },

    async editItemConform() {
      this.dialogEdit.loading = true

      const response = await Api.editClassSchool({
        id: this.editedItem._id,
        class_name: this.editedItem.class_name,
        leader: this.editedItem.leader,
        lecture_amount: this.editedItem.lecture_amount,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false
        this.getData()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    async getClasses() {
      const response = await Api.getClass()

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.classes = response.data.results
      }
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    async AddClass() {
      this.addDialog.saveLoading = true

      const response = await Api.addClassSchool(this.class_data.selectedClass, this.class_data.class_school_leader, this.class_data.lecture_amount)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addDialog.open = false
        this.addDialog.saveLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.addDialog.open = false
        this.addDialog.saveLoading = false
        this.getData()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },


    printPage(item) {
      // let routeData = this.$router.resolve({ name: 'studentBillInvoice', params: { student_id: item.student_id, student_name: item.student_name, student_mobile: item.student_name, student_class: (item.account_division_current.class_name + ' - ' + item.account_division_current.leader) } });

      let routeData = this.$router.resolve({ name: `classSchoolInvoice`, params: { id: `${item._id}` } });
      window.open(routeData.href, '_blank');

      // localStorage.setItem("classSchoolInvoice", JSON.stringify(item))

      // router.push('studentBillInvoice')
    },
  },
}
</script>

<style>
.teacher_image_table {
  cursor: pointer;
}
</style>

<style scoped>
a {
  text-decoration: none;
}
</style>
